<template>
  <div class="ware-box">
    <Header/>
    <new-home-header title="WMS仓库管理系统" @freeClick="btnClick" :content="content" @btnClick="bannerClick"
    :price="bannerPrice" :imgSrc="`${require('@/assets/img/payImages/WMSWare.png')}`" imgStyle="height:360px" :free-btn="false"/>
    <div class="page-content">
      <div class="card-title">您的企业是否正面临以下困扰?</div>
      <div class="item-list">
        <div class="quest-item-box" v-for="item in questList" :key=item.background
             :style="{backgroundColor:item.background}">
          <div class="item-img" :style="{backgroundColor:item.img}">
            <span>{{ item.istr }}</span>
          </div>
          <div class="str-box">
            <span>{{ item.str1 }}</span>
            <span>{{ item.str2 }}</span>
            <span>{{ item.str3 }}</span>
          </div>
        </div>
      </div>
      <div class="jiantou-box">
        <el-image style="width: 60px; height: 50px;margin-right: 10px;" fit="fill"
                  :src="`${require('@/assets/img/payImages/jiantou.png')}`"/>
        <el-image style="width: 110px; height: 90px;margin-right: 10px;" fit="fill"
                  :src="`${require('@/assets/img/payImages/jiantou.png')}`"/>
        <el-image style="width: 60px; height: 50px;margin-right: 10px;" fit="fill"
                  :src="`${require('@/assets/img/payImages/jiantou.png')}`"/>
      </div>
      <div class="foot-item-list">
        <div class="foot-item" v-for="item in fiveFootList" :key="item.title">
          <div class="out-box">
            <div class="title-box">{{ item.title }}</div>
            <div>
              <div class="con-list" v-for="row in item.list" :key="row">
                <div class="statubox">
                  <i class="el-icon-check statuicon"/>
                </div>
                <span class="str">&nbsp;&nbsp;{{ row }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wms-box">
      <div class="card-title">WMS仓储管理系统助力企业持续提质降本增效</div>
      <div class="logistics-box">
        <div class="logistics-box-cell" v-for="(item,i) in logistics" :key="i">
          <div class="logistics-box-cell-title">
            <div class="blue-icon">
              <img :src="`${require('@/assets/img/payImages/'+item.img)}`" class="img"/>
            </div>
            <div class="logistics-box-cell-title-left">{{ item.title }}</div>
            <div class="logistics-box-cell-title-right">{{ item.num }}</div>
          </div>
          <div class="logistics-box-content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="wms-box1">
      <div class="card-title">WMS仓储管理系统能为你实现</div>
      <div class="erp-container finance">
        <div class="finance-left">
          <div class="finance-cell" v-for="(item,i) in erpList" :key="i">
            <div class="finance-cell-title">{{ item.title }}</div>
            <div class="finance-cell-tips">{{ item.content }}</div>
          </div>
        </div>
        <div class="finance-right">
          <el-image style="width: 654px; height: 420px" :src="`${require('@/assets/img/payImages/wms.png')}`"
                    fit="fill"/>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'WMS仓库管理系统'"/>
    <Footer/>
  </div>
</template>
<script>
import bannerList from "@/components/assembly/bannerList.vue";
import newHomeHeader from "@/components/assembly/newHomeHeander";
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import Footer from "@/views/home/newCloudeFooter.vue";

export default {
  name: "warehouse",
  bannerPrice:'市场价格：150000元',
  components: {bannerList, newHomeHeader, Header, applyTrial, Footer},
  methods: {
    btnClick() {
      this.$router.push('/appShop')
    },
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  },
  data() {
    return {
      questList: [
        {
          background: 'rgba(229, 233, 255, 1)',
          img: 'rgba(25, 53, 222, 1)',
          str1: '作业效率低',
          str2: '货物收发慢',
          str3: '备货不及时',
          istr: '低'
        },
        {
          background: 'rgba(255, 226, 229, 1)',
          img: 'rgba(254, 39, 59, 1)',
          str1: '物流成本高',
          str2: '人力投入大',
          str3: '浪费较严重',
          istr: '高'
        },
        {
          background: 'rgba(255, 244, 228, 1)',
          img: 'rgba(255, 152, 0, 1)',
          str1: '仓库管理混乱',
          str2: '库存不精准',
          str3: '货物难寻找',
          istr: '乱'
        },
        {
          background: 'rgba(231, 226, 255, 1)',
          img: 'rgba(81, 47, 228, 1)',
          str1: '质量追溯难',
          str2: '问题料难查',
          str3: '残次品难追',
          istr: '难'
        },
      ],
      fiveFootList: [
        {title: '进货管理', list: ['到货预约', '扫码收货', '移动检验', '感应入库', '上架指引', '快捷上架']},
        {title: '库内管理', list: ['区域细分', '路径规划', '即时盘点', '批量移库', '库存速查', '库存预警',]},
        {title: '生产管理', list: ['在线派工', '工序转移', '设备监管', '工艺路线', '快速报工', '投料履历',]},
        {title: '出货管理', list: ['出库策略', '任务分配', '拣货引导', '移动拣货', '扫码下架', '进度查询',]},
        {title: '追溯管理', list: ['正向追溯', '逆向追溯', '定位追踪', '产品履历', '防伪查询', '防窜管理',]},
      ],
      logistics: [
        {
          img: 'top3_ico1(1).png',
          title: '物流成本降低',
          num: '20%',
          content: '从领料、加工到出货，全流程移动化数据采，去除不必要的人力，信息及时准确，降低错误、浪费、管理成本'
        },
        {
          img: 'top3_ico2(1).png',
          title: '作业效率提升',
          num: '45%',
          content: '消息及时推送，任务智能分配，整体流程优化，部门高协效同，收货入库、库内管理、拣配出货移动化管理，效率大大提升'
        },
        {
          img: 'top3_ico3(1).png',
          title: '仓库利用率提升',
          num: '30%',
          content: '仓库空间合理规划，各类容器精准细分，人员、设备充分利用，有效减少资源浪费，提高仓库昨用率'
        },
        {
          img: 'top3_ico4(1).png',
          title: '库存准确率提升至',
          num: '100%',
          content: '库存高效盘点，仓库高度透明，信息实时查看，准确率大大提升。库存安全预警，大大降低库存风险，有效保证生产'
        },
        {
          img: 'top3_ico5(1).png',
          title: '生产质量提升',
          num: '25%',
          content: '物流各环节层层关联，可以快速追溯到车间、机台、作业人员等，生产品质持续得以改善'
        },
        {
          img: 'top3_ico6.png',
          title: '订单交付准时率提升',
          num: '80%',
          content: '仓库周转效率提高，快速完成出入库，同时降 低出错率。单次出货时间大大缩短，订单准时交付率大幅提升'
        },
      ],
      erpList: [
        {
          title: 'wms系统多仓库、多货主支持',
          content: 'wms系统支持多仓库和多货主管理，客户可通过网络集中管理异地仓库。不同货主可享受差异化仓库管理服务。系统支持联动作业、全局掌握和局部协调，实时查看分析统计报表。'
        },
        {title: '强大的配置功能', content: 'wms系统提供多种策略：上架、拣选、补货、波次、盘点、码托、持续补货、ABC、库间补货等，客户可根据需要配置自己的系统流程，提高作业效率。'},
        {title: '精益化库内管理', content: 'WMS系统根据库内工序对作业进行分类，自动分配给操作员完成，记录员工工作内容，便于KPI考核；系统实时提供库存数据，支持各种特殊作业要求。'},
      ],
      content: '通过数据采集设备，如条码扫描器、RFID读写器等，实现对仓库中的物品信息的准确采集和识别。通过智能化的任务分派和接收功能，指导仓库操作人员完成入库、出库、盘点、移库等各种库内作业。',
    }
  }
}
</script>
<style lang="scss" scoped>
.ware-box {
  min-width: 1360px;
}

.wms-box {
  padding: 60px 0;
  background-color: rgba(247, 248, 254, 1);
}

.wms-box1 {
  padding: 60px 0;
}

.jiantou-box {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: end;
  margin-top: 10px;
}

.img {
  width: 40px;
  height: 40px;
}

.item-list {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.finance-left > div:last-child {
  border-bottom: none;
}

.quest-item-box {
  width: 250px;
  height: 60px;
  padding: 20px;
  display: flex;
  align-items: center;

  .item-img {
    width: 60px;
    height: 60px;
    font-size: 24px;
    font-family: 'SourceHanSansCN';

    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .str-box {
    margin-left: 20px;
    font-size: 16px;
    font-family: 'SourceHanSansCN';
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
}

.finance {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: auto;

  &-left {
    margin-right: 40px;

    .finance-cell {
      //margin-bottom: 60px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px dashed rgba(25, 53, 222, 1);


      &-title {
        font-size: 22px;
        font-weight: bold;
        color: #333;
        margin-bottom: 14px;
      }

      &-tips {
        color: #666666;
        font-size: 16px;
      }
    }

    .left-btns {
      display: flex;
    }
  }
}

.page-content {
  padding: 60px 0;
}

.logistics-box {
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .blue-icon {
    width: 80px;
    height: 80px;
    background: #1935DE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  &-cell {
    width: 390px;
    height: 250px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 30px 30px 0;
    margin-bottom: 12px;

    &-title {
      display: flex;
      align-items: center;
      font-size: 18px;

      &-left {
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }

      &-right {
        color: #FE273B;
        font-weight: bold;
        margin-left: 5px;
        font-size: 24px;
      }
    }

    .logistics-box-content {
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid rgba(204, 208, 231, 1);
      line-height: 1.8;
      font-family: 'SourceHanSansCN';
    }
  }
}

.out-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding-bottom: 30px;
}

.foot-item-list {
  width: 1200px;
  padding: 30px 0;
  margin: auto;
  box-shadow: 2px -1px 10px 0px #eee;
  display: flex;
  justify-content: space-around;

  .foot-item {
    .title-box {
      width: 200px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: 'SourceHanSansCN';
      color: #FFFFFF;
      background-color: rgba(25, 53, 222, 1);
    }
  }
}

.statubox {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(31, 200, 159, 1);
}

.statuicon {
  color: #fff;
  font-size: 12px;
}

.con-list {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
